import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {GiCycling} from 'react-icons/gi'
import {GiMountainCave} from 'react-icons/gi'
import {GiSandsOfTime} from 'react-icons/gi'

import { AnimationOnScroll } from 'react-animation-on-scroll';

const InfoStyle = styled.section`
    max-width: 1600px;
    margin: auto;
`
const InfoDist = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 30px;

    h3{
        font-size: 1.5em;
        text-align: center;
        font-weight: 100;
        padding: 20px 0px;
    }
`
const LinkTrack = styled(Link)`
    text-decoration: none;
    color: black;
    :hover{
        color: #53000D;
    }
`


const Item = styled.div`
    background-color: red;
    padding: 20px 30px;
    margin: 10px 10px;
    background: rgb(52,210,119);
    background: linear-gradient(180deg, rgba(52,210,119,0.3) 0%, rgba(83,0,13,0.5) 100%);
    /* border: 5px solid #FFFFFF; */
    border-radius: 25px;
    -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
    
    h2{
        text-align: right;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: "Righteous", cursive;
        font-size: 2.5em;
        padding-bottom: 20px;
    }
    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 5px;
        p{
            text-align: center;
            font-size: 2em;
            line-height: 1;
        }
        .icon{
            width: 64px;
            height: 64px;
        }
    }
`

const Title = styled.h3`
    text-align: center;
    font-size: 2em;
    margin: auto;
    padding: 30px 0px 10px 0px;
    font-weight: 500;
    font-family: "Righteous", cursive;
    color: #53000D;
`
const Info = () => {
    return (
        <InfoStyle>  
            <Title>Trzy trasy</Title>
            <InfoDist>
                <h3>Trasa maratonu wiedzie malowniczymi drogami. Pozostawi Ci fascynujące wrażenia i wspomnienia. A spotkane po drodze osoby mogą zaowocować przyjaźnią na całe życie.</h3>
                
                <LinkTrack to='/'>
                    <Item>
                        <h2>gigante</h2>
                        <div>
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiCycling className='icon'/></AnimationOnScroll>
                            <p>650km<br></br>przygody</p>         
                        </div>
                        <div>
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiMountainCave className='icon'/>
                            </AnimationOnScroll>
                            <p>4500m<br></br>wspinaczki</p>         
                        </div>
                        <div>
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                                <GiSandsOfTime className='icon'/>
                            </AnimationOnScroll>
                            <p>90h<br></br>limitu czasu</p>         
                        </div>
                    </Item>
                </LinkTrack>
                <LinkTrack to='/'>
                <Item>
                    <h2>classico</h2>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiCycling className='icon'/>
                        </AnimationOnScroll>
                        <p>350km<br></br>przygody</p>         
                    </div>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiMountainCave className='icon'/>
                        </AnimationOnScroll>
                        <p>2500m<br></br>wspinaczki</p>         
                    </div>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiSandsOfTime className='icon'/>
                        </AnimationOnScroll>
                        <p>70h<br></br>limitu czasu</p>         
                    </div>
                </Item>
                </LinkTrack>
                <LinkTrack to='/'>
                <Item>
                    <h2>piccolo</h2>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiCycling className='icon'/>
                        </AnimationOnScroll>
                        <p>120km<br></br>przygody</p>         
                    </div>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiMountainCave className='icon'/>
                        </AnimationOnScroll>
                        <p>1000m<br></br>wspinaczki</p>         
                    </div>
                    <div>
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                            <GiSandsOfTime className='icon'/>
                        </AnimationOnScroll>
                        <p>12h<br></br>limitu czasu</p>         
                    </div>
                </Item>
                </LinkTrack>
            </InfoDist>
        </InfoStyle>
    );
}

export default Info;
