import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';


const ImageHeroStyle = styled(Link)`
    display: grid;
    text-decoration: none;
    padding: 30px 0;
    cursor: pointer;


    
    .img{
        grid-area: 1/1;
        width: 100%;
        height: 300px;
        filter: brightness(75%);
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);   
    }
    

    div{
        grid-area: 1/1;
        position: relative;

        place-items: center;
        display:grid;
        h1{
            color: white;
            font-size: 3rem;
            font-weight: 200;
            /* text-transform: uppercase; */
            font-family: "Roboto", sans-serif;

        }
    }
    ${({theme})=> theme.media.tablet}{
      .img{
        height: 400px; 
      }   
  }

`

const GaleriaSection = () => {
    return (
        <ImageHeroStyle to='/Galeria' className='linkStyle'>
        
            <StaticImage 
                placeholder="blurred"
                alt="more ultra race"
                // transformOptions={{ fit: "fill", cropFocus: "entropy" }}

                className="img"
                lcayout="constrained"
                src="../../assets/images/homepage/sgall5.png"
                formats={["auto", "webp", "avif"]} />
            <div>
                <h1>Galeria</h1>
            </div>
        </ImageHeroStyle>
    )
}

export default GaleriaSection;
