import React from 'react'

import styled from 'styled-components'
import { Link } from 'gatsby'
import Lista_startowa_roztocze from "../assets/Lista_startowaURR24.pdf"

import HeroSection from './Hompage/HeroSection'

import { AnimationOnScroll } from 'react-animation-on-scroll';

const Header = styled.header`
    align-items: center;
    justify-content: center;
    margin: auto;
    min-height: 60vh;
    .img{
        height: 400px;
        width: auto;
        ${({theme})=> theme.media.tablet}{
            height: 600px;
        }
    } 
`

const TitleS = styled.h1`
  text-align: center;
  margin: auto;
  padding-top: 30px;
  justify-content: center;
`
const ListaLink = styled.a`
  color: black;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;

  :hover{
    color: #34D277;
  }
`
const ButtonZapisy = styled.button`
    margin:  10px auto;
    color: #34D277;
    font-size: 1em;
    background-color: white;
    border: 2px solid #34D277;
    /* border-radius: 15px; */
    padding: 12px 15px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.6s linear;
    width: 200px;

   
    &:hover{
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        background-color: #34D277;
        color: white;
        border: 2px solid transparent;
    }
`

const Intro = styled.section`
    display: block;
    text-align: center;
    margin: 20px 30px;
    /* border-bottom: 5px double #53000D; */
    
    h1{
        font-size: 2.2em;
        padding-bottom: 20px;
        color: #53000D;
        font-weight: 500;
        font-family: "Righteous", cursive;
    }
    h2{
        font-size: 1.3em;
        font-weight: 300;
        margin: 20px 0;

    }

    ${({theme})=> theme.media.tablet}{
        max-width: 60%;
        margin: auto;
        padding: 10px 40px;
        h1{
            padding: 10px;
            font-size: 2.5em;
        }
        h2{
            font-size: 1.5em;
            font-weight: 200;
        }
    }
`

const Hero = () => {
    return (
        <Header>
                <HeroSection/>
            <Intro>
                <h1>
                ultramaraton gravelowy<br/><spam>09-11.05.2025</spam>
                </h1>
                <h2>120/350/650 km rowerowej przygody wśród malowniczych krajobrazów Roztocza. Łagodne pagórki, smukłe szutry, leśne dukty. Poczuj zapach sosen, wilgoć lasu...</h2>
                <AnimationOnScroll animateIn="animate__zoomIn" duration={2} animateOnce={true}>
                {/* <TitleS>
                    <ListaLink href={Lista_startowa_roztocze} title="Lista Startowa URR 2024">Lista Startowa URR 2024</ListaLink>
                </TitleS> */}

                {/* <Link to='/zapisy'>
                    <ButtonZapisy>jadę Roztocze!</ButtonZapisy>
                </Link> */}
                </AnimationOnScroll>
            </Intro>
        </Header>
    );
}

export default Hero;
