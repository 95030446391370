import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components';

const MapStyle = styled.section`
    text-align: center;
    
    .img{
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);   
        /* height: 400px; */
        
    }
    ${({theme})=> theme.media.tablet}{
            width: 100%;
        }

`
const Map = () => {
    return (
        <MapStyle>
            <StaticImage
              src="../assets/images/mapaR.jpg"
              alt="zawody rowerowe roztocze
              "
              placeholder="blurred"
              layout='constrained'
            //   width={600}
              height={600}     
              transformOptions={{ fit: "cover", cropFocus: "attention" }}
              className='img'
              quality={100}
             
            ></StaticImage>
        </MapStyle>
    );
}

export default Map;
