import * as React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Info from "../components/Info"
import Seo from "../components/Seo"
import Sponsor from "../components/Sponsor"
import { Helmet } from "react-helmet"
import Map from "../components/Map"
import GaleriaSection from "../components/Hompage/GaleriaSection"
import Countdown from "../components/countdown"
import Podcast from "../components/Podcast"
import YouTube from "../components/YouTube"
import Newsletter from "../components/Newsletter"





const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Ultramaraton Gravelowy"/>
      <Helmet>
        <meta name="facebook-domain-verification"content="6w6wdb73vshsrnpixxhwgdkbryi1cg" />
      </Helmet>
      <Hero/>
      
      <YouTube/>
      <Countdown/>
      <Info/>
      {/* <Newsletter/> */}
      {/* <Map/> */}
      <GaleriaSection/>
      <Sponsor/> 
      <Podcast/>
    </Layout>
  )
}

export default IndexPage
